
// helpers for program
import get from '@neonaut/lib-js/es/object/getPath';
import merge from '@neonaut/lib-js/es/object/deep-extend';
import replace from '@neonaut/lib-js/es/string/replaceRegex';

import createCachedStyleFunction from '@mapsight/lib-ol/style/createCachedStyleFunction';

import __vectorStyle_fill from 'ol/style/Fill';
import __vectorStyle_stroke from 'ol/style/Stroke';
import __vectorStyle_text from 'ol/style/Text';
import __vectorStyle_image from 'ol/style/Image';
import __vectorStyle_icon from 'ol/style/Icon';
import __vectorStyle_circle from 'ol/style/Circle';
import __vectorStyle_style from 'ol/style/Style';

const createHash = a => JSON.stringify(a);

export default createCachedStyleFunction({
	constructorsMap: {fill: __vectorStyle_fill,
stroke: __vectorStyle_stroke,
text: __vectorStyle_text,
image: __vectorStyle_image,
icon: __vectorStyle_icon,
circle: __vectorStyle_circle,
style: __vectorStyle_style,},
	allowedStyles: ["traffic","userGeolocation","features","traffic2","draw"],
	allowedProps: ["state","trafficSituation","los","accuracy","mapsightIconId","isTemporaryRestriction","type","title","occupancyTrendString","mass_restriction","height_restriction","cluster","clusterSize","spread","spreadFeatureLine","spreadFeatureDot","directions","monitoringShortname"],
	declarationHashFunction: (env, props, hashPrefix, geometryType, style) => {
		let hash = hashPrefix + '|' + geometryType + '|';
		const addHash = a => { hash += ',' + a };

		
		addHash(1);
		switch (style) {
			case 'traffic':
				addHash(2);
				if (((env.zoom < 15))) {
					addHash(3);
				}
				if (((env.zoom < 12))) {
					addHash(4);
				}
				if (((env.zoom < 9))) {
					addHash(5);
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					addHash(6);
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					addHash(7);
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					addHash(8);
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					addHash(9);
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					addHash(10);
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					addHash(11);
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					addHash(12);
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					addHash(13);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					addHash(14);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 17) || (props['los'] == 2 && env.zoom < 17))) {
					addHash(15);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					addHash(16);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					addHash(17);
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					addHash(18);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(19);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(20);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					addHash(21);
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					addHash(22);
				}
				if (((env.zoom < 15))) {
					addHash(23);
				}
				if (((env.zoom < 12))) {
					addHash(24);
				}
				if (((env.zoom < 9))) {
					addHash(25);
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					addHash(26);
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					addHash(27);
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					addHash(28);
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					addHash(29);
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					addHash(30);
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					addHash(31);
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					addHash(32);
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					addHash(33);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					addHash(34);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 17) || (props['los'] == 2 && env.zoom < 17))) {
					addHash(35);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					addHash(36);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					addHash(37);
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					addHash(38);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(39);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(40);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					addHash(41);
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					addHash(42);
				}
				break;
			case 'userGeolocation':
				addHash(43);
				addHash('@' + createHash(props['accuracy']));
				break;
			case 'features':
				addHash(44);
				if (((props['mapsightIconId'] == 'dot') || (env['mapsightIconId'] == 'dot'))) {
					addHash(45);
				}
				if (((props['mapsightIconId'] == 0) || (env['mapsightIconId'] == 0))) {
					addHash(46);
				}
				if (((props['mapsightIconId'] == 1) || (env['mapsightIconId'] == 1))) {
					addHash(47);
				}
				if (((props['mapsightIconId'] == 2) || (env['mapsightIconId'] == 2))) {
					addHash(48);
				}
				if (((props['mapsightIconId'] == 3) || (env['mapsightIconId'] == 3))) {
					addHash(49);
				}
				if (((props['mapsightIconId'] == 4) || (env['mapsightIconId'] == 4))) {
					addHash(50);
				}
				if (((props['mapsightIconId'] == 5) || (env['mapsightIconId'] == 5))) {
					addHash(51);
				}
				if (((props['mapsightIconId'] == 6) || (env['mapsightIconId'] == 6))) {
					addHash(52);
				}
				if (((props['mapsightIconId'] == 7) || (env['mapsightIconId'] == 7))) {
					addHash(53);
				}
				if (((props['mapsightIconId'] == 8) || (env['mapsightIconId'] == 8))) {
					addHash(54);
				}
				if (((props['mapsightIconId'] == 9) || (env['mapsightIconId'] == 9))) {
					addHash(55);
				}
				if (((props['mapsightIconId'] == 'a') || (env['mapsightIconId'] == 'a'))) {
					addHash(56);
				}
				if (((props['mapsightIconId'] == 'abfall_recycling') || (env['mapsightIconId'] == 'abfall_recycling'))) {
					addHash(57);
				}
				if (((props['mapsightIconId'] == 'ampel') || (env['mapsightIconId'] == 'ampel'))) {
					addHash(58);
				}
				if (((props['mapsightIconId'] == 'b') || (env['mapsightIconId'] == 'b'))) {
					addHash(59);
				}
				if (((props['mapsightIconId'] == 'baustelle') || (env['mapsightIconId'] == 'baustelle'))) {
					addHash(60);
				}
				if (((props['mapsightIconId'] == 'behindertenparken') || (env['mapsightIconId'] == 'behindertenparken'))) {
					addHash(61);
				}
				if (((props['mapsightIconId'] == 'bike') || (env['mapsightIconId'] == 'bike'))) {
					addHash(62);
				}
				if (((props['mapsightIconId'] == 'bilbiothek_archiv') || (env['mapsightIconId'] == 'bilbiothek_archiv'))) {
					addHash(63);
				}
				if (((props['mapsightIconId'] == 'breitenbeschraenkung') || (env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(64);
				}
				if (((props['mapsightIconId'] == 'bundr') || (env['mapsightIconId'] == 'bundr'))) {
					addHash(65);
				}
				if (((props['mapsightIconId'] == 'busparkplatz') || (env['mapsightIconId'] == 'busparkplatz'))) {
					addHash(66);
				}
				if (((props['mapsightIconId'] == 'c') || (env['mapsightIconId'] == 'c'))) {
					addHash(67);
				}
				if (((props['mapsightIconId'] == 'cam') || (env['mapsightIconId'] == 'cam'))) {
					addHash(68);
				}
				if (((props['mapsightIconId'] == 'charging-station') || (env['mapsightIconId'] == 'charging-station'))) {
					addHash(69);
				}
				if (((props['mapsightIconId'] == 'd') || (env['mapsightIconId'] == 'd'))) {
					addHash(70);
				}
				if (((props['mapsightIconId'] == 'db') || (env['mapsightIconId'] == 'db'))) {
					addHash(71);
				}
				if (((props['mapsightIconId'] == 'e') || (env['mapsightIconId'] == 'e'))) {
					addHash(72);
				}
				if (((props['mapsightIconId'] == 'einbahnstrasse') || (env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(73);
				}
				if (((props['mapsightIconId'] == 'f') || (env['mapsightIconId'] == 'f'))) {
					addHash(74);
				}
				if (((props['mapsightIconId'] == 'faehre') || (env['mapsightIconId'] == 'faehre'))) {
					addHash(75);
				}
				if (((props['mapsightIconId'] == 'fahrbahnverengung') || (env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(76);
				}
				if (((props['mapsightIconId'] == 'flag') || (env['mapsightIconId'] == 'flag'))) {
					addHash(77);
				}
				if (((props['mapsightIconId'] == 'flag-finish') || (env['mapsightIconId'] == 'flag-finish'))) {
					addHash(78);
				}
				if (((props['mapsightIconId'] == 'flag-start') || (env['mapsightIconId'] == 'flag-start'))) {
					addHash(79);
				}
				if (((props['mapsightIconId'] == 'flughafen') || (env['mapsightIconId'] == 'flughafen'))) {
					addHash(80);
				}
				if (((props['mapsightIconId'] == 'forschung') || (env['mapsightIconId'] == 'forschung'))) {
					addHash(81);
				}
				if (((props['mapsightIconId'] == 'friedhof') || (env['mapsightIconId'] == 'friedhof'))) {
					addHash(82);
				}
				if (((props['mapsightIconId'] == 'g') || (env['mapsightIconId'] == 'g'))) {
					addHash(83);
				}
				if (((props['mapsightIconId'] == 'gegenverkehr') || (env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(84);
				}
				if (((props['mapsightIconId'] == 'gewerbe') || (env['mapsightIconId'] == 'gewerbe'))) {
					addHash(85);
				}
				if (((props['mapsightIconId'] == 'h') || (env['mapsightIconId'] == 'h'))) {
					addHash(86);
				}
				if (((props['mapsightIconId'] == 'haltestelle') || (env['mapsightIconId'] == 'haltestelle'))) {
					addHash(87);
				}
				if (((props['mapsightIconId'] == 'hoehenbeschraenkung') || (env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(88);
				}
				if (((props['mapsightIconId'] == 'hotel') || (env['mapsightIconId'] == 'hotel'))) {
					addHash(89);
				}
				if (((props['mapsightIconId'] == 'i') || (env['mapsightIconId'] == 'i'))) {
					addHash(90);
				}
				if (((props['mapsightIconId'] == 'info') || (env['mapsightIconId'] == 'info'))) {
					addHash(91);
				}
				if (((props['mapsightIconId'] == 'j') || (env['mapsightIconId'] == 'j'))) {
					addHash(92);
				}
				if (((props['mapsightIconId'] == 'k') || (env['mapsightIconId'] == 'k'))) {
					addHash(93);
				}
				if (((props['mapsightIconId'] == 'kita') || (env['mapsightIconId'] == 'kita'))) {
					addHash(94);
				}
				if (((props['mapsightIconId'] == 'krankenhaus_klinik') || (env['mapsightIconId'] == 'krankenhaus_klinik'))) {
					addHash(95);
				}
				if (((props['mapsightIconId'] == 'l') || (env['mapsightIconId'] == 'l'))) {
					addHash(96);
				}
				if (((props['mapsightIconId'] == 'lkwsperrung') || (env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(97);
				}
				if (((props['mapsightIconId'] == 'm') || (env['mapsightIconId'] == 'm'))) {
					addHash(98);
				}
				if (((props['mapsightIconId'] == 'marker') || (env['mapsightIconId'] == 'marker'))) {
					addHash(99);
				}
				if (((props['mapsightIconId'] == 'meldung') || (env['mapsightIconId'] == 'meldung'))) {
					addHash(100);
				}
				if (((props['mapsightIconId'] == 'motorradstellplatz') || (env['mapsightIconId'] == 'motorradstellplatz'))) {
					addHash(101);
				}
				if (((props['mapsightIconId'] == 'museum') || (env['mapsightIconId'] == 'museum'))) {
					addHash(102);
				}
				if (((props['mapsightIconId'] == 'n') || (env['mapsightIconId'] == 'n'))) {
					addHash(103);
				}
				if (((props['mapsightIconId'] == 'o') || (env['mapsightIconId'] == 'o'))) {
					addHash(104);
				}
				if (((props['mapsightIconId'] == 'ort') || (env['mapsightIconId'] == 'ort'))) {
					addHash(105);
				}
				if (((props['mapsightIconId'] == 'p') || (env['mapsightIconId'] == 'p'))) {
					addHash(106);
				}
				if (((props['mapsightIconId'] == 'parkanlage') || (env['mapsightIconId'] == 'parkanlage'))) {
					addHash(107);
				}
				if (((props['mapsightIconId'] == 'parkflaeche') || (env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(108);
				}
				if (((props['mapsightIconId'] == 'parkhaus') || (env['mapsightIconId'] == 'parkhaus'))) {
					addHash(109);
				}
				if (((props['mapsightIconId'] == 'pundr') || (env['mapsightIconId'] == 'pundr'))) {
					addHash(110);
				}
				if (((props['mapsightIconId'] == 'q') || (env['mapsightIconId'] == 'q'))) {
					addHash(111);
				}
				if (((props['mapsightIconId'] == 'r') || (env['mapsightIconId'] == 'r'))) {
					addHash(112);
				}
				if (((props['mapsightIconId'] == 's') || (env['mapsightIconId'] == 's'))) {
					addHash(113);
				}
				if (((props['mapsightIconId'] == 'schule') || (env['mapsightIconId'] == 'schule'))) {
					addHash(114);
				}
				if (((props['mapsightIconId'] == 'sehenswuerdigkeit') || (env['mapsightIconId'] == 'sehenswuerdigkeit'))) {
					addHash(115);
				}
				if (((props['mapsightIconId'] == 'sport') || (env['mapsightIconId'] == 'sport'))) {
					addHash(116);
				}
				if (((props['mapsightIconId'] == 'sportanlage') || (env['mapsightIconId'] == 'sportanlage'))) {
					addHash(117);
				}
				if (((props['mapsightIconId'] == 'stadtteil') || (env['mapsightIconId'] == 'stadtteil'))) {
					addHash(118);
				}
				if (((props['mapsightIconId'] == 'stau') || (env['mapsightIconId'] == 'stau'))) {
					addHash(119);
				}
				if (((props['mapsightIconId'] == 't') || (env['mapsightIconId'] == 't'))) {
					addHash(120);
				}
				if (((props['mapsightIconId'] == 'taxi') || (env['mapsightIconId'] == 'taxi'))) {
					addHash(121);
				}
				if (((props['mapsightIconId'] == 'theater') || (env['mapsightIconId'] == 'theater'))) {
					addHash(122);
				}
				if (((props['mapsightIconId'] == 'tiefgarage') || (env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(123);
				}
				if (((props['mapsightIconId'] == 'tonnage') || (env['mapsightIconId'] == 'tonnage'))) {
					addHash(124);
				}
				if (((props['mapsightIconId'] == 'u') || (env['mapsightIconId'] == 'u'))) {
					addHash(125);
				}
				if (((props['mapsightIconId'] == 'umleitung') || (env['mapsightIconId'] == 'umleitung'))) {
					addHash(126);
				}
				if (((props['mapsightIconId'] == 'v') || (env['mapsightIconId'] == 'v'))) {
					addHash(127);
				}
				if (((props['mapsightIconId'] == 'vollsperrung') || (env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(128);
				}
				if (((props['mapsightIconId'] == 'w') || (env['mapsightIconId'] == 'w'))) {
					addHash(129);
				}
				if (((props['mapsightIconId'] == 'x') || (env['mapsightIconId'] == 'x'))) {
					addHash(130);
				}
				if (((props['mapsightIconId'] == 'y') || (env['mapsightIconId'] == 'y'))) {
					addHash(131);
				}
				if (((props['mapsightIconId'] == 'z') || (env['mapsightIconId'] == 'z'))) {
					addHash(132);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'dot'))) {
					addHash(133);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					addHash(134);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					addHash(135);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					addHash(136);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					addHash(137);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					addHash(138);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					addHash(139);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					addHash(140);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					addHash(141);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					addHash(142);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					addHash(143);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					addHash(144);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'abfall_recycling'))) {
					addHash(145);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					addHash(146);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					addHash(147);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(148);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'behindertenparken'))) {
					addHash(149);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bike'))) {
					addHash(150);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bilbiothek_archiv'))) {
					addHash(151);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(152);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bundr'))) {
					addHash(153);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'busparkplatz'))) {
					addHash(154);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					addHash(155);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					addHash(156);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'charging-station'))) {
					addHash(157);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					addHash(158);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'db'))) {
					addHash(159);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					addHash(160);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(161);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					addHash(162);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'faehre'))) {
					addHash(163);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(164);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					addHash(165);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(166);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(167);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flughafen'))) {
					addHash(168);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'forschung'))) {
					addHash(169);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'friedhof'))) {
					addHash(170);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					addHash(171);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(172);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gewerbe'))) {
					addHash(173);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					addHash(174);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'haltestelle'))) {
					addHash(175);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(176);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hotel'))) {
					addHash(177);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					addHash(178);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'info'))) {
					addHash(179);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					addHash(180);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					addHash(181);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'kita'))) {
					addHash(182);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'krankenhaus_klinik'))) {
					addHash(183);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					addHash(184);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(185);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					addHash(186);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'marker'))) {
					addHash(187);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					addHash(188);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'motorradstellplatz'))) {
					addHash(189);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'museum'))) {
					addHash(190);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					addHash(191);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					addHash(192);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					addHash(193);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					addHash(194);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkanlage'))) {
					addHash(195);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(196);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					addHash(197);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					addHash(198);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					addHash(199);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					addHash(200);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					addHash(201);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'schule'))) {
					addHash(202);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sehenswuerdigkeit'))) {
					addHash(203);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sport'))) {
					addHash(204);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sportanlage'))) {
					addHash(205);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stadtteil'))) {
					addHash(206);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					addHash(207);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					addHash(208);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'taxi'))) {
					addHash(209);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'theater'))) {
					addHash(210);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(211);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(212);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					addHash(213);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(214);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					addHash(215);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(216);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					addHash(217);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					addHash(218);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					addHash(219);
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					addHash(220);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(221);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					addHash(222);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(223);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(224);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					addHash(225);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(226);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(227);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(228);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(229);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(230);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					addHash(231);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					addHash(232);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(233);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					addHash(234);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					addHash(235);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(236);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(237);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(238);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(239);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(240);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					addHash(241);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					addHash(242);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(243);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(244);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					addHash(245);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(246);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(247);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(248);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(249);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(250);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					addHash(251);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					addHash(252);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(253);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					addHash(254);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					addHash(255);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(256);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(257);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(258);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(259);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(260);
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					addHash(261);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(262);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 0) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 0))) {
					addHash(263);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 1) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 1))) {
					addHash(264);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 2) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 2))) {
					addHash(265);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 3) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 3))) {
					addHash(266);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 4) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 4))) {
					addHash(267);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 5) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 5))) {
					addHash(268);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 6) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 6))) {
					addHash(269);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 7) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 7))) {
					addHash(270);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 8) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 8))) {
					addHash(271);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 9) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 9))) {
					addHash(272);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'a') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'a'))) {
					addHash(273);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					addHash(274);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'b') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'b'))) {
					addHash(275);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(276);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(277);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'c') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'c'))) {
					addHash(278);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					addHash(279);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'd') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'd'))) {
					addHash(280);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'e') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'e'))) {
					addHash(281);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(282);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'f') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'f'))) {
					addHash(283);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(284);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'g') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'g'))) {
					addHash(285);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(286);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'h') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'h'))) {
					addHash(287);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(288);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'i') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'i'))) {
					addHash(289);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'j') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'j'))) {
					addHash(290);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'k') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'k'))) {
					addHash(291);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'l') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'l'))) {
					addHash(292);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(293);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'm') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'm'))) {
					addHash(294);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					addHash(295);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'n') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'n'))) {
					addHash(296);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'o') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'o'))) {
					addHash(297);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					addHash(298);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'p') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'p'))) {
					addHash(299);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(300);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkhaus') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkhaus'))) {
					addHash(301);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					addHash(302);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'q') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'q'))) {
					addHash(303);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'r') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'r'))) {
					addHash(304);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 's') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 's'))) {
					addHash(305);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					addHash(306);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 't') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 't'))) {
					addHash(307);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tiefgarage') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(308);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(309);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'u') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'u'))) {
					addHash(310);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(311);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'v') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'v'))) {
					addHash(312);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(313);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'w') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'w'))) {
					addHash(314);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'x') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'x'))) {
					addHash(315);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'y') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'y'))) {
					addHash(316);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'z') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'z'))) {
					addHash(317);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(318);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					addHash(319);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(320);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					addHash(321);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					addHash(322);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					addHash(323);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					addHash(324);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					addHash(325);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					addHash(326);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					addHash(327);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					addHash(328);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					addHash(329);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					addHash(330);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					addHash(331);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					addHash(332);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					addHash(333);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(334);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(335);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					addHash(336);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					addHash(337);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					addHash(338);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					addHash(339);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(340);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					addHash(341);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(342);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					addHash(343);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(344);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					addHash(345);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(346);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					addHash(347);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					addHash(348);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					addHash(349);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					addHash(350);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(351);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					addHash(352);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					addHash(353);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					addHash(354);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					addHash(355);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					addHash(356);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					addHash(357);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(358);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					addHash(359);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					addHash(360);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					addHash(361);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					addHash(362);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					addHash(363);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					addHash(364);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					addHash(365);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(366);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(367);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					addHash(368);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(369);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					addHash(370);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(371);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					addHash(372);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					addHash(373);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					addHash(374);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					addHash(375);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(376);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					addHash(377);
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(378);
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					addHash(379);
				}
				if (((props['state'] == 'select'))) {
					addHash(380);
				}
				if (((props['mapsightIconId'] == 'stau'))) {
					addHash(381);
				}
				if (((props['mapsightIconId'] == 'umleitung'))) {
					addHash(382);
				}
				if (((props['isTemporaryRestriction']))) {
					addHash(383);
				}
				if (((props['type'] == 'trunk') || (props['type'] == 'trunk_link'))) {
					addHash(384);
				}
				if (((props['type'] == 'primary') || (props['type'] == 'primary_link'))) {
					addHash(385);
				}
				if (((geometryType == 'Polygon') || (geometryType == 'MultiLineString'))) {
					addHash(386);
				}
				if (((geometryType == 'MultiPolygon'))) {
					addHash(387);
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					addHash(388);
				}
				if (((props['state'] == 'highlight'))) {
					addHash(389);
				}
				if (((props['state'] == 'highlight' && geometryType == 'Polygon') || (props['state'] == 'highlight' && geometryType == 'MultiLineString') || (props['state'] == 'highlight' && geometryType == 'MultiPolygon'))) {
					addHash(390);
				}
				if (((props['state'] == 'select'))) {
					addHash(391);
				}
				if (((props['state'] == 'select' && geometryType == 'Polygon') || (props['state'] == 'select' && geometryType == 'MultiLineString') || (props['state'] == 'select' && geometryType == 'MultiPolygon'))) {
					addHash(392);
				}
				if (((props['type'] == 'parking' && props['title'] && env.zoom >= 15))) {
					addHash(393);
					addHash('@' + createHash(props['title']));
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13) || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select'))) {
					addHash(394);
					addHash('@' + createHash(props['occupancyTrendString']));
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13 && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13 && props['state'] == 'select') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight' && props['state'] == 'select') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select' && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select'))) {
					addHash(395);
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 15))) {
					addHash(396);
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 12) || (props['type'] == 'default' && props['height_restriction'] && env.zoom >= 12))) {
					addHash(397);
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 13) || (props['type'] == 'default' && props['height_restriction'] && env.zoom >= 13))) {
					addHash(398);
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['mass_restriction'] && props['state'] == 'select') || (props['type'] == 'default' && props['height_restriction'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['height_restriction'] && props['state'] == 'select'))) {
					addHash(399);
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 12))) {
					addHash(400);
					addHash('@' + createHash(props['mass_restriction']));
				}
				if (((props['type'] == 'default' && props['height_restriction'] && env.zoom >= 12))) {
					addHash(401);
					addHash('@' + createHash(props['height_restriction']));
				}
				if (((props['cluster']))) {
					addHash(402);
					addHash('@' + createHash(props['clusterSize']));
				}
				if (((props['cluster'] && props['state'] == 'highlight'))) {
					addHash(403);
				}
				if (((props['cluster'] && props['state'] == 'select'))) {
					addHash(404);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(405);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 0) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 0))) {
					addHash(406);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 1) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 1))) {
					addHash(407);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 2) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 2))) {
					addHash(408);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 3) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 3))) {
					addHash(409);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 4) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 4))) {
					addHash(410);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 5) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 5))) {
					addHash(411);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 6) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 6))) {
					addHash(412);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 7) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 7))) {
					addHash(413);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 8) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 8))) {
					addHash(414);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 9) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 9))) {
					addHash(415);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'a') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'a'))) {
					addHash(416);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					addHash(417);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'b') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'b'))) {
					addHash(418);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(419);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(420);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'c') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'c'))) {
					addHash(421);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					addHash(422);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'd') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'd'))) {
					addHash(423);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'e') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'e'))) {
					addHash(424);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(425);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'f') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'f'))) {
					addHash(426);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(427);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'g') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'g'))) {
					addHash(428);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(429);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'h') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'h'))) {
					addHash(430);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(431);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'i') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'i'))) {
					addHash(432);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'j') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'j'))) {
					addHash(433);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'k') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'k'))) {
					addHash(434);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'l') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'l'))) {
					addHash(435);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(436);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'm') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'm'))) {
					addHash(437);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					addHash(438);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'n') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'n'))) {
					addHash(439);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'o') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'o'))) {
					addHash(440);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					addHash(441);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'p') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'p'))) {
					addHash(442);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(443);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkhaus') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkhaus'))) {
					addHash(444);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					addHash(445);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'q') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'q'))) {
					addHash(446);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'r') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'r'))) {
					addHash(447);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 's') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 's'))) {
					addHash(448);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					addHash(449);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 't') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 't'))) {
					addHash(450);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tiefgarage') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(451);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(452);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'u') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'u'))) {
					addHash(453);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(454);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'v') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'v'))) {
					addHash(455);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(456);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'w') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'w'))) {
					addHash(457);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'x') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'x'))) {
					addHash(458);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'y') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'y'))) {
					addHash(459);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'z') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'z'))) {
					addHash(460);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(461);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					addHash(462);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(463);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					addHash(464);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					addHash(465);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					addHash(466);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					addHash(467);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					addHash(468);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					addHash(469);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					addHash(470);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					addHash(471);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					addHash(472);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					addHash(473);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					addHash(474);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					addHash(475);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					addHash(476);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					addHash(477);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					addHash(478);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					addHash(479);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					addHash(480);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					addHash(481);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					addHash(482);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					addHash(483);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					addHash(484);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					addHash(485);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					addHash(486);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					addHash(487);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					addHash(488);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					addHash(489);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					addHash(490);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					addHash(491);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					addHash(492);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					addHash(493);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					addHash(494);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					addHash(495);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					addHash(496);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					addHash(497);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					addHash(498);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					addHash(499);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					addHash(500);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					addHash(501);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					addHash(502);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					addHash(503);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					addHash(504);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					addHash(505);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					addHash(506);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					addHash(507);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					addHash(508);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					addHash(509);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					addHash(510);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					addHash(511);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					addHash(512);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					addHash(513);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					addHash(514);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					addHash(515);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					addHash(516);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					addHash(517);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					addHash(518);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					addHash(519);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					addHash(520);
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					addHash(521);
				}
				if (((props['spreadFeatureLine']))) {
					addHash(522);
				}
				if (((props['spreadFeatureDot']))) {
					addHash(523);
				}
				if (((props['state'] == 'highlight' && props['spreadFeatureLine']) || (props['state'] == 'highlight' && props['spreadFeatureDot']) || (props['state'] == 'select' && props['spreadFeatureLine']) || (props['state'] == 'select' && props['spreadFeatureDot']))) {
					addHash(524);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N')))) {
					addHash(525);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N') && props['state'] == 'highlight'))) {
					addHash(526);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N') && props['state'] == 'select'))) {
					addHash(527);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE')))) {
					addHash(528);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE') && props['state'] == 'highlight'))) {
					addHash(529);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE') && props['state'] == 'select'))) {
					addHash(530);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E')))) {
					addHash(531);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E') && props['state'] == 'highlight'))) {
					addHash(532);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E') && props['state'] == 'select'))) {
					addHash(533);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE')))) {
					addHash(534);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE') && props['state'] == 'highlight'))) {
					addHash(535);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE') && props['state'] == 'select'))) {
					addHash(536);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S')))) {
					addHash(537);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S') && props['state'] == 'highlight'))) {
					addHash(538);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S') && props['state'] == 'select'))) {
					addHash(539);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW')))) {
					addHash(540);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW') && props['state'] == 'highlight'))) {
					addHash(541);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW') && props['state'] == 'select'))) {
					addHash(542);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W')))) {
					addHash(543);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W') && props['state'] == 'highlight'))) {
					addHash(544);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W') && props['state'] == 'select'))) {
					addHash(545);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW')))) {
					addHash(546);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW') && props['state'] == 'highlight'))) {
					addHash(547);
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW') && props['state'] == 'select'))) {
					addHash(548);
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && env.zoom >= 14))) {
					addHash(549);
					addHash('@' + createHash(props['monitoringShortname']));
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && env.zoom >= 16))) {
					addHash(550);
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['monitoringShortname'] && props['state'] == 'select'))) {
					addHash(551);
				}
				break;
			case 'traffic2':
				addHash(552);
				if (((env.zoom < 20))) {
					addHash(553);
				}
				if (((env.zoom < 18))) {
					addHash(554);
				}
				if (((env.zoom < 16))) {
					addHash(555);
				}
				if (((env.zoom < 15))) {
					addHash(556);
				}
				if (((env.zoom < 12))) {
					addHash(557);
				}
				if (((env.zoom < 9))) {
					addHash(558);
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					addHash(559);
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					addHash(560);
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					addHash(561);
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					addHash(562);
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					addHash(563);
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					addHash(564);
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					addHash(565);
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					addHash(566);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					addHash(567);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					addHash(568);
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					addHash(569);
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					addHash(570);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(571);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					addHash(572);
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					addHash(573);
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					addHash(574);
				}
				break;
			case 'draw':
				addHash(575);
				break;
		}

		return hash;
	},
	declarationFunction: (env, props, geometryType, style) => {
		const declaration = {};
		const declare = a => merge(declaration, a);

		
		declare({ 'default': { 'zIndex': { 'value': 100 },'fill': { 'color': { 'value': 'rgba(255, 0, 0, 0.46)' } },'stroke': { 'color': { 'value': 'rgba(224, 109, 208, 0.9)' } },'text': { 'font': { 'value': 'bold' },'fill': { 'color': { 'value': '#0c6490' } },'stroke': { 'color': { 'value': '#fff' },'width': { 'value': 3 } } } } });
		switch (style) {
			case 'traffic':
				declare({ 'default': { 'image': { 'type': { 'value': 'none' } },'stroke': { 'width': { 'value': 4 },'color': { 'value': 'rgba(255, 156, 4, 0.7)' } } } });
				if (((env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 3 } } } });
				}
				if (((env.zoom < 12))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 2 } } } });
				}
				if (((env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 4, 0.5)' } } } });
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 156, 4)' } } } });
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.3)' } } } });
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 0, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 26, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 26, 0.2)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 179, 26)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.8)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 17) || (props['los'] == 2 && env.zoom < 17))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.75)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 156, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					declare({ 'default': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'scale': { 'value': 0.5 },'src': { 'value': '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2021-11-03-13-00' },'snapToPixel': { 'value': 'true' },'offsetX': { 'value': 0 },'offsetY': { 'value': 11312 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } },'stroke': { 'color': { 'value': 'rgba(255, 0, 0, 0.8)' } },'zIndex': { 'value': 107 } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 676 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 0, 0, 0.6)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 0, 102)' } } } });
				}
				if (((env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 3 } } } });
				}
				if (((env.zoom < 12))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 2 } } } });
				}
				if (((env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 4, 0.5)' } } } });
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 156, 4)' } } } });
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.3)' } } } });
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 0, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 26, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 26, 0.2)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 179, 26)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.8)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 17) || (props['los'] == 2 && env.zoom < 17))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.75)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 156, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					declare({ 'default': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'scale': { 'value': 0.5 },'src': { 'value': '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2021-11-03-13-00' },'snapToPixel': { 'value': 'true' },'offsetX': { 'value': 0 },'offsetY': { 'value': 11312 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } },'stroke': { 'color': { 'value': 'rgba(255, 0, 0, 0.8)' } },'zIndex': { 'value': 107 } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 676 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 0, 0, 0.6)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 0, 102)' } } } });
				}
				break;
			case 'userGeolocation':
				declare({ 'accuracy': { 'image': { 'type': { 'value': 'circle' } },'circle': { 'radius': { 'value': '' + eval('' + props['accuracy'] + ' / ' + env['resolution'] + '') + '' },'fill': { 'color': { 'value': 'rgba(51, 153, 204, 0.61)' } } } },'point': { 'image': { 'type': { 'value': 'circle' } },'circle': { 'radius': { 'value': 6 },'fill': { 'color': { 'value': '#3399cc' } } } } });
				break;
			case 'features':
				declare({ 'default': { 'stroke': { 'width': { 'value': 4 } },'image': { 'type': { 'value': 'icon' } },'icon': { 'scale': { 'value': 0.5 },'src': { 'value': '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2021-11-03-13-00' },'snapToPixel': { 'value': 'true' },'offsetX': { 'value': 0 },'offsetY': { 'value': 10080 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } },'zIndex': { 'value': 102 },'text': { 'offsetY': { 'value': -20 } } } });
				if (((props['mapsightIconId'] == 'dot') || (env['mapsightIconId'] == 'dot'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2036 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['mapsightIconId'] == 0) || (env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4888 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 1) || (env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4976 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 2) || (env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5064 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 3) || (env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5152 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 4) || (env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5240 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 5) || (env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5328 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 6) || (env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5416 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 7) || (env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5504 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 8) || (env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5592 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 9) || (env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5680 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'a') || (env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5768 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'abfall_recycling') || (env['mapsightIconId'] == 'abfall_recycling'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5856 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'ampel') || (env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5944 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'b') || (env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6032 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'baustelle') || (env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6120 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'behindertenparken') || (env['mapsightIconId'] == 'behindertenparken'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6208 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'bike') || (env['mapsightIconId'] == 'bike'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6296 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'bilbiothek_archiv') || (env['mapsightIconId'] == 'bilbiothek_archiv'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6384 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'breitenbeschraenkung') || (env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6472 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'bundr') || (env['mapsightIconId'] == 'bundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6560 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'busparkplatz') || (env['mapsightIconId'] == 'busparkplatz'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6648 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'c') || (env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6736 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'cam') || (env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6824 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'charging-station') || (env['mapsightIconId'] == 'charging-station'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6912 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'd') || (env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7000 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'db') || (env['mapsightIconId'] == 'db'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7088 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'e') || (env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7176 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'einbahnstrasse') || (env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7264 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'f') || (env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7352 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'faehre') || (env['mapsightIconId'] == 'faehre'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7440 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'fahrbahnverengung') || (env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7528 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'flag') || (env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7616 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'flag-finish') || (env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7704 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'flag-start') || (env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7792 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'flughafen') || (env['mapsightIconId'] == 'flughafen'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7880 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'forschung') || (env['mapsightIconId'] == 'forschung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7968 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'friedhof') || (env['mapsightIconId'] == 'friedhof'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8056 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'g') || (env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8144 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'gegenverkehr') || (env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8232 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'gewerbe') || (env['mapsightIconId'] == 'gewerbe'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8320 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'h') || (env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8408 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'haltestelle') || (env['mapsightIconId'] == 'haltestelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8496 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'hoehenbeschraenkung') || (env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8584 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'hotel') || (env['mapsightIconId'] == 'hotel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8672 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'i') || (env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8760 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'info') || (env['mapsightIconId'] == 'info'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8848 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'j') || (env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8936 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'k') || (env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9024 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'kita') || (env['mapsightIconId'] == 'kita'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9112 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'krankenhaus_klinik') || (env['mapsightIconId'] == 'krankenhaus_klinik'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9200 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'l') || (env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9288 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'lkwsperrung') || (env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9376 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'm') || (env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9464 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'marker') || (env['mapsightIconId'] == 'marker'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9552 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'meldung') || (env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9640 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'motorradstellplatz') || (env['mapsightIconId'] == 'motorradstellplatz'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9728 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'museum') || (env['mapsightIconId'] == 'museum'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9816 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'n') || (env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9904 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'o') || (env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9992 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'ort') || (env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10080 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'p') || (env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10168 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'parkanlage') || (env['mapsightIconId'] == 'parkanlage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10256 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'parkflaeche') || (env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10344 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'parkhaus') || (env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10432 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'pundr') || (env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10520 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'q') || (env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10608 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'r') || (env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10696 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 's') || (env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10784 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'schule') || (env['mapsightIconId'] == 'schule'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10872 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'sehenswuerdigkeit') || (env['mapsightIconId'] == 'sehenswuerdigkeit'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10960 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'sport') || (env['mapsightIconId'] == 'sport'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11048 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'sportanlage') || (env['mapsightIconId'] == 'sportanlage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11136 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'stadtteil') || (env['mapsightIconId'] == 'stadtteil'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11224 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'stau') || (env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11312 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 't') || (env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11400 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'taxi') || (env['mapsightIconId'] == 'taxi'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11488 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'theater') || (env['mapsightIconId'] == 'theater'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11576 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'tiefgarage') || (env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11664 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'tonnage') || (env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11752 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'u') || (env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11840 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'umleitung') || (env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11928 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'v') || (env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12016 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'vollsperrung') || (env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12104 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'w') || (env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12192 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'x') || (env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12280 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'y') || (env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12368 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((props['mapsightIconId'] == 'z') || (env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12456 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'dot'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2036 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4888 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4976 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5064 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5152 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5240 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5328 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5416 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5504 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5592 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5680 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5768 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'abfall_recycling'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5856 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 5944 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6032 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6120 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'behindertenparken'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6208 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bike'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6296 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bilbiothek_archiv'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6384 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6472 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'bundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6560 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'busparkplatz'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6648 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6736 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6824 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'charging-station'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 6912 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7000 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'db'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7088 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7176 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7264 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7352 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'faehre'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7440 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7528 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7616 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7704 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7792 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flughafen'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7880 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'forschung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 7968 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'friedhof'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8056 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8144 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8232 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gewerbe'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8320 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8408 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'haltestelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8496 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8584 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hotel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8672 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8760 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'info'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8848 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 8936 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9024 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'kita'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9112 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'krankenhaus_klinik'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9200 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9288 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9376 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9464 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'marker'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9552 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9640 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'motorradstellplatz'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9728 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'museum'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9816 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9904 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 9992 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10080 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10168 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkanlage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10256 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10344 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10432 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10520 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10608 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10696 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10784 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'schule'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10872 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sehenswuerdigkeit'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 10960 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sport'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11048 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'sportanlage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11136 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stadtteil'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11224 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11312 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11400 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'taxi'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11488 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'theater'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11576 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11664 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11752 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11840 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 11928 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12016 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12104 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12192 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12280 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12368 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 12456 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 520 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 0 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 52 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 104 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 156 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 208 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 260 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 312 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 364 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 416 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 468 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 520 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 572 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 624 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 676 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 728 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 780 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 832 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2356 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2420 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2484 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 0 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 52 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 104 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 156 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 208 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 260 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 312 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 364 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 416 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 468 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 520 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 572 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 624 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 676 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 728 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 780 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 832 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2356 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2420 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2484 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 0) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 884 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 1) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 948 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 2) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1012 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 3) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1076 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 4) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1140 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 5) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1204 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 6) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1268 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 7) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1332 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 8) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1396 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 9) || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1460 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'a') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1524 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1588 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'b') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1652 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1716 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1780 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'c') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1844 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1908 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'd') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1972 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'e') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2100 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2164 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'f') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2228 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2292 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'g') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2548 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2612 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'h') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2676 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2740 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'i') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2804 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'j') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2868 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'k') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2932 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'l') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2996 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3060 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'm') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3124 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3188 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'n') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3252 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'o') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3316 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'p') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3444 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3508 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkhaus') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3572 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3636 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'q') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3700 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'r') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3764 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 's') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3828 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 't') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3956 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tiefgarage') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4020 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4084 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'u') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4148 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4212 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'v') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4276 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4340 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'w') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4404 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'x') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4468 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'y') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4532 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'z') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4596 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4660 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4736 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4812 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 884 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 948 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1012 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1076 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1140 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1204 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1268 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1332 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1396 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1460 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1524 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1588 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1652 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1716 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1780 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1844 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1908 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1972 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2100 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2164 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2228 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2292 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2548 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2612 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2676 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2740 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2804 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2868 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2932 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2996 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3060 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3124 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3188 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3252 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3316 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3444 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3508 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3572 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3636 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3700 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3764 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3828 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3956 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4020 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4084 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4148 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4212 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4276 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4340 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4404 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4468 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4532 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4596 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4660 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4736 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4812 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					declare({ 'selectionBackground': { 'image': { 'type': { 'value': 'circle' } },'circle': { 'radius': { 'value': 26 },'fill': { 'color': { 'value': 'rgba(128, 255, 255, 0.4)' } },'stroke': { 'value': 'none' } } } });
				}
				if (((props['state'] == 'select'))) {
					declare({ 'selectionBackground': { 'circle': { 'fill': { 'color': { 'value': 'rgba(87, 255, 255, 0.8)' } } } } });
				}
				if (((props['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': '#f00' } } } });
				}
				if (((props['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': '#2aadea' } } } });
				}
				if (((props['isTemporaryRestriction']))) {
					declare({ 'default': { 'stroke': { 'lineDash': { 'value': '5, 15' } } } });
				}
				if (((props['type'] == 'trunk') || (props['type'] == 'trunk_link'))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 4 } } } });
				}
				if (((props['type'] == 'primary') || (props['type'] == 'primary_link'))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 3 } } } });
				}
				if (((geometryType == 'Polygon') || (geometryType == 'MultiLineString'))) {
					declare({ 'default': { 'zIndex': { 'value': 101 } } });
				}
				if (((geometryType == 'MultiPolygon'))) {
					declare({ 'default': { 'zIndex': { 'value': 100 } } });
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': '#3afffe' } },'cursor': { 'value': 'pointer' } } });
				}
				if (((props['state'] == 'highlight'))) {
					declare({ 'default': { 'zIndex': { 'value': 105 } } });
				}
				if (((props['state'] == 'highlight' && geometryType == 'Polygon') || (props['state'] == 'highlight' && geometryType == 'MultiLineString') || (props['state'] == 'highlight' && geometryType == 'MultiPolygon'))) {
					declare({ 'default': { 'zIndex': { 'value': 100 } } });
				}
				if (((props['state'] == 'select'))) {
					declare({ 'default': { 'zIndex': { 'value': 305 } } });
				}
				if (((props['state'] == 'select' && geometryType == 'Polygon') || (props['state'] == 'select' && geometryType == 'MultiLineString') || (props['state'] == 'select' && geometryType == 'MultiPolygon'))) {
					declare({ 'default': { 'zIndex': { 'value': 100 } } });
				}
				if (((props['type'] == 'parking' && props['title'] && env.zoom >= 15))) {
					declare({ 'default': { 'text': { 'font': { 'value': 'bold 12px sans-serif' },'offsetX': { 'value': 17 },'offsetY': { 'value': 1 },'textAlign': { 'value': 'left' },'fill': { 'color': { 'value': '#03437e' } },'stroke': { 'color': { 'value': '#fff' },'width': { 'value': 1 } },'text': { 'value': '' + props['title'] + '' } } } });
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13) || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select'))) {
					declare({ 'background': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'anchorX': { 'value': '-14px' },'anchorY': { 'value': '12px' },'src': { 'value': '/assets/img/parking-dynamic/' + props['occupancyTrendString'] + '.png' } } } });
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13 && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 13 && props['state'] == 'select') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'highlight' && props['state'] == 'select') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select' && props['state'] == 'highlight') || (props['type'] == 'parking' && props['occupancyTrendString'] && props['state'] == 'select'))) {
					declare({ 'background': { 'zIndex': { 'value': 105 } } });
				}
				if (((props['type'] == 'parking' && props['occupancyTrendString'] && env.zoom >= 15))) {
					declare({ 'default': { 'text': { 'offsetX': { 'value': 29 } } } });
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 12) || (props['type'] == 'default' && props['height_restriction'] && env.zoom >= 12))) {
					declare({ 'default': { 'text': { 'font': { 'value': 'bold 11px sans-serif' },'offsetX': { 'value': 0 },'offsetY': { 'value': 13 },'textAlign': { 'value': 'center' },'fill': { 'color': { 'value': '#03437e' } },'stroke': { 'color': { 'value': '#fff' },'width': { 'value': 1 } } } } });
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 13) || (props['type'] == 'default' && props['height_restriction'] && env.zoom >= 13))) {
					declare({ 'default': { 'text': { 'offsetY': { 'value': 19 },'font': { 'value': 'bold 13px sans-serif' } } } });
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['mass_restriction'] && props['state'] == 'select') || (props['type'] == 'default' && props['height_restriction'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['height_restriction'] && props['state'] == 'select'))) {
					declare({ 'default': { 'text': { 'fill': { 'color': { 'value': '#003163' } },'stroke': { 'width': { 'value': 2 } },'offsetY': { 'value': 22 } } } });
				}
				if (((props['type'] == 'default' && props['mass_restriction'] && env.zoom >= 12))) {
					declare({ 'default': { 'text': { 'text': { 'value': '' + props['mass_restriction'] + '' } } } });
				}
				if (((props['type'] == 'default' && props['height_restriction'] && env.zoom >= 12))) {
					declare({ 'default': { 'text': { 'text': { 'value': '' + props['height_restriction'] + 'm' } } } });
				}
				if (((props['cluster']))) {
					declare({ 'clusterLabel': { 'text': { 'font': { 'value': 'bold 13px sans-serif' },'offsetX': { 'value': 5 },'offsetY': { 'value': 5 },'textAlign': { 'value': 'center' },'fill': { 'color': { 'value': '#ad1a00' } },'stroke': { 'color': { 'value': '#fff' },'width': { 'value': 1 } },'text': { 'value': '' + props['clusterSize'] + '' } },'zIndex': { 'value': 102 } } });
				}
				if (((props['cluster'] && props['state'] == 'highlight'))) {
					declare({ 'clusterLabel': { 'zIndex': { 'value': 107 } } });
				}
				if (((props['cluster'] && props['state'] == 'select'))) {
					declare({ 'clusterLabel': { 'zIndex': { 'value': 307 } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 0) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 884 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 1) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 948 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 2) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1012 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 3) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1076 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 4) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1140 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 5) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1204 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 6) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1268 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 7) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1332 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 8) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1396 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 9) || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1460 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'a') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1524 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ampel') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1588 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'b') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1652 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'baustelle') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1716 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'breitenbeschraenkung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1780 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'c') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1844 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'cam') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1908 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'd') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1972 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'e') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2100 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'einbahnstrasse') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2164 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'f') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2228 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'fahrbahnverengung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2292 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'g') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2548 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'gegenverkehr') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2612 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'h') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2676 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'hoehenbeschraenkung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2740 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'i') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2804 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'j') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2868 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'k') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2932 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'l') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2996 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'lkwsperrung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3060 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'm') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3124 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'meldung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3188 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'n') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3252 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'o') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3316 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'ort') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'p') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3444 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkflaeche') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3508 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'parkhaus') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3572 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'pundr') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3636 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'q') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3700 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'r') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3764 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 's') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3828 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'stau') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 't') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3956 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tiefgarage') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4020 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'tonnage') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4084 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'u') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4148 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'umleitung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4212 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'v') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4276 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'vollsperrung') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4340 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'w') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4404 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'x') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4468 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'y') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4532 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'z') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4596 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-finish') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4660 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4736 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && props['mapsightIconId'] == 'flag-start') || (props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4812 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 0))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 884 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 1))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 948 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 2))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1012 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 3))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1076 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 4))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1140 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 5))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1204 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 6))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1268 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 7))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1332 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 8))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1396 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 9))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1460 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'a'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1524 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ampel'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1588 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'b'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1652 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'baustelle'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1716 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'breitenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1780 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'c'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1844 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'cam'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1908 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'd'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 1972 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'e'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2100 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'einbahnstrasse'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2164 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'f'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2228 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'fahrbahnverengung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2292 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'g'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2548 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'gegenverkehr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2612 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'h'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2676 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'hoehenbeschraenkung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2740 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'i'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2804 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'j'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2868 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'k'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2932 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'l'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 2996 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'lkwsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3060 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'm'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3124 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'meldung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3188 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'n'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3252 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'o'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3316 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'ort'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3380 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'p'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3444 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkflaeche'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3508 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'parkhaus'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3572 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'pundr'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3636 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'q'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3700 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'r'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3764 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 's'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3828 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'stau'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 't'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3956 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tiefgarage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4020 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'tonnage'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4084 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'u'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4148 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'umleitung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4212 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'v'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4276 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'vollsperrung'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4340 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'w'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4404 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'x'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4468 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'y'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4532 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'z'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4596 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-finish'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4660 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4736 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spread'] && props['state'] != 'highlight' && props['state'] != 'select' && env['mapsightIconUse'] == 'force' && env['mapsightIconId'] == 'flag-start'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 4812 },'sizeX': { 'value': 76 },'sizeY': { 'value': 76 } } } });
				}
				if (((props['spreadFeatureLine']))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 1 },'color': { 'value': '#000000' } } } });
				}
				if (((props['spreadFeatureDot']))) {
					declare({ 'default': { 'image': { 'type': { 'value': 'circle' } },'circle': { 'radius': { 'value': 3 },'fill': { 'color': { 'value': '#000000' } } } } });
				}
				if (((props['state'] == 'highlight' && props['spreadFeatureLine']) || (props['state'] == 'highlight' && props['spreadFeatureDot']) || (props['state'] == 'select' && props['spreadFeatureLine']) || (props['state'] == 'select' && props['spreadFeatureDot']))) {
					declare({ 'default': { 'cursor': { 'value': 'default' } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N')))) {
					declare({ 'direction-arrow-n': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 0 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-n': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('N') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-n': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE')))) {
					declare({ 'direction-arrow-ne': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 0.7853981634 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-ne': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NE') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-ne': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E')))) {
					declare({ 'direction-arrow-e': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 1.5707963268 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-e': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('E') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-e': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE')))) {
					declare({ 'direction-arrow-se': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 2.3561944902 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-se': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SE') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-se': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S')))) {
					declare({ 'direction-arrow-s': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 3.1415926536 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-s': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('S') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-s': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW')))) {
					declare({ 'direction-arrow-sw': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 3.926990817 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-sw': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('SW') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-sw': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W')))) {
					declare({ 'direction-arrow-w': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 4.7123889804 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-w': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('W') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-w': { 'zIndex': { 'value': 308 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW')))) {
					declare({ 'direction-arrow-nw': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'src': { 'value': '/assets/img/directions-arrow@2x.png' },'scale': { 'value': 0.5 },'rotation': { 'value': 5.4977871438 } },'zIndex': { 'value': 103 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW') && props['state'] == 'highlight'))) {
					declare({ 'direction-arrow-nw': { 'zIndex': { 'value': 108 } } });
				}
				if (((env.zoom >= 13 && props['directions'] && props.directions.includes('NW') && props['state'] == 'select'))) {
					declare({ 'direction-arrow-nw': { 'zIndex': { 'value': 308 } } });
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && env.zoom >= 14))) {
					declare({ 'default': { 'text': { 'text': { 'value': '' + props['monitoringShortname'] + '' },'font': { 'value': 'bold 10px sans-serif' },'offsetX': { 'value': 0 },'offsetY': { 'value': 27 },'textAlign': { 'value': 'center' },'fill': { 'color': { 'value': 'rgba(0, 0, 0, 0.7)' } },'stroke': { 'color': { 'value': 'rgba(255, 255, 255, 0.6)' },'width': { 'value': 1 } } } } });
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && env.zoom >= 16))) {
					declare({ 'default': { 'text': { 'offsetY': { 'value': 30 },'font': { 'value': 'bold 12px sans-serif' } } } });
				}
				if (((props['type'] == 'default' && props['monitoringShortname'] && props['state'] == 'highlight') || (props['type'] == 'default' && props['monitoringShortname'] && props['state'] == 'select'))) {
					declare({ 'default': { 'text': { 'offsetY': { 'value': 30 } },'fill': { 'color': { 'value': 'rgb(21, 130, 130)' } } } });
				}
				break;
			case 'traffic2':
				declare({ 'default': { 'image': { 'type': { 'value': 'none' } },'stroke': { 'width': { 'value': 6 },'color': { 'value': 'rgba(255, 156, 4, 0.7)' } } } });
				if (((env.zoom < 20))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 7 } } } });
				}
				if (((env.zoom < 18))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 5 } } } });
				}
				if (((env.zoom < 16))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 4 } } } });
				}
				if (((env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 3 } } } });
				}
				if (((env.zoom < 12))) {
					declare({ 'default': { 'stroke': { 'width': { 'value': 2 } } } });
				}
				if (((env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 156, 4, 0.5)' } } } });
				}
				if (((props['state'] == 'highlight') || (props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 156, 4)' } } } });
				}
				if (((props['trafficSituation'] == null) || (props['trafficSituation'] == 'Unbekannt') || (props['trafficSituation'] == 'UNKNOWN_ERROR') || (props['los'] == null) || (props['los'] == 0))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 15) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 15) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 15) || (props['los'] == null && env.zoom < 15) || (props['los'] == 0 && env.zoom < 15))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == null && env.zoom < 9) || (props['trafficSituation'] == 'Unbekannt' && env.zoom < 9) || (props['trafficSituation'] == 'UNKNOWN_ERROR' && env.zoom < 9) || (props['los'] == null && env.zoom < 9) || (props['los'] == 0 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 0, 0, 0.3)' } } } });
				}
				if (((props['trafficSituation'] == null && props['state'] == 'highlight') || (props['trafficSituation'] == null && props['state'] == 'select') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Unbekannt' && props['state'] == 'select') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'highlight') || (props['trafficSituation'] == 'UNKNOWN_ERROR' && props['state'] == 'select') || (props['los'] == null && props['state'] == 'highlight') || (props['los'] == null && props['state'] == 'select') || (props['los'] == 0 && props['state'] == 'highlight') || (props['los'] == 0 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 0, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei') || (props['los'] == 1))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 54, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && env.zoom < 9) || (props['los'] == 1 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(0, 179, 54, 0.2)' } } } });
				}
				if (((props['trafficSituation'] == 'Frei' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Frei' && props['state'] == 'select') || (props['los'] == 1 && props['state'] == 'highlight') || (props['los'] == 1 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(0, 179, 54)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend') || (props['los'] == 2))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 82, 0, 0.7)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && env.zoom < 9) || (props['los'] == 2 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(255, 82, 0, 0.5)' } } } });
				}
				if (((props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Zaehfliessend' && props['state'] == 'select') || (props['los'] == 2 && props['state'] == 'highlight') || (props['los'] == 2 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 82, 0)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau') || (props['los'] == 3) || (props['los'] == 4))) {
					declare({ 'default': { 'image': { 'type': { 'value': 'icon' } },'icon': { 'scale': { 'value': 0.5 },'src': { 'value': '/assets/img/mapsight-traffic-style-icon-sprite-2x.png?v=2021-11-03-13-00' },'snapToPixel': { 'value': 'true' },'offsetX': { 'value': 0 },'offsetY': { 'value': 11312 },'sizeX': { 'value': 88 },'sizeY': { 'value': 88 } },'stroke': { 'color': { 'value': 'rgba(174, 0, 5, 0.8)' } },'zIndex': { 'value': 107 } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 1 && env.zoom <= 10 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 676 },'sizeX': { 'value': 52 },'sizeY': { 'value': 52 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 3 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select') || (props['los'] == 4 && env.zoom >= 11 && env.zoom <= 12 && props['state'] != 'highlight' && props['state'] != 'select'))) {
					declare({ 'default': { 'icon': { 'offsetX': { 'value': 0 },'offsetY': { 'value': 3892 },'sizeX': { 'value': 64 },'sizeY': { 'value': 64 } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && env.zoom < 9) || (props['los'] == 3 && env.zoom < 9) || (props['los'] == 4 && env.zoom < 9))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgba(174, 0, 5, 0.6)' } } } });
				}
				if (((props['trafficSituation'] == 'Stau' && props['state'] == 'highlight') || (props['trafficSituation'] == 'Stau' && props['state'] == 'select') || (props['los'] == 3 && props['state'] == 'highlight') || (props['los'] == 3 && props['state'] == 'select') || (props['los'] == 4 && props['state'] == 'highlight') || (props['los'] == 4 && props['state'] == 'select'))) {
					declare({ 'default': { 'stroke': { 'color': { 'value': 'rgb(255, 0, 102)' } } } });
				}
				break;
			case 'draw':
				declare({ 'default': { 'stroke': { 'width': { 'value': 4 },'color': { 'value': '#467aff' } },'image': { 'type': { 'value': 'circle' } },'circle': { 'radius': { 'value': 5 },'fill': { 'color': { 'value': 'white' } },'stroke': { 'width': { 'value': 2 },'color': { 'value': '#467aff' } } } } });
				break;
		}

		return declaration;
	},
});

